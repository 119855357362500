<template>
  <v-container class="presetPassi">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Preset Checklist</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="preset"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          class="table thead-light"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                @click="dialogNew = true"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  ms-3
                "
                color="primary"
                >Aggiungi</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.azioni="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editPreset(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  v-bind="attrs"
                  v-on="on"
                  width="36"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-warning
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#fb6340"
                >
                  <v-icon x-small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica Checklist</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="removePreset(item['_id'])"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  v-bind="attrs"
                  v-on="on"
                  class="
                    font-weight-600
                    text-capitalize
                    btn-ls btn-danger
                    me-2
                    my-2
                    rounded-sm
                  "
                  color="#f5365c"
                >
                  <v-icon x-small>fas fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Elimina Checklist</span>
            </v-tooltip>

          </template>
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog
      v-model="dialogScheda"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <SchedaPreset
        v-if="dialogScheda"
        :isEditing="isEditingPreset"
        :presetProp="presetClicked"
        @chiudiDialogScheda="chiudiDialogScheda"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogNew"
      persistent
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewPreset v-if="dialogNew" @chiudiDialogNew="chiudiDialogNew" />
    </v-dialog>
  </v-container>
</template>

<script>
import apiPreset from "@/js/pages/preset";
import SchedaPreset from "@/components/SchedaPreset.vue";
import NewPreset from "@/components/NewPreset.vue";

export default {
  name: "presetPassi",
  components: {
    SchedaPreset,
    NewPreset,
  },
  data() {
    return {
      preset: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "30%",
        },
        { text: "Descrizione", value: "descrizione", width: "50%" },
        { text: "Azioni", value: "azioni", width: "15%" },
      ],
      isEditingPreset: false,
      dialogScheda: false,
      presetClicked: undefined,
      dialogNew: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.preset.length / this.itemsPerPage);
    },
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async chiudiDialogScheda(refresh = false) {
      if (refresh) await this.reloadLista();
      this.dialogScheda = false;
    },
    async chiudiDialogNew() {
      await this.reloadLista();
      this.dialogNew = false;
    },
    async reloadLista() {
      let preset = await apiPreset.getAll(this.token);
      //Array.prototype.push.apply(utenti, utenti);
      this.preset = preset;
    },
    async editPreset(item) {
      this.isEditingPreset = true;
      this.presetClicked = item;
      this.dialogScheda = true;
    },
    async removePreset(id_preset) {
      if (confirm("Eliminare il preset?")) {
        let data = { _id: id_preset };
        await apiPreset.deleteOne(this.token, data);
        this.reloadLista();
      }
    },
  },
};
</script>