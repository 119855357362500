<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Scheda Checklist</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogScheda')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form v-model="formValid" ref="form_data" lazy-validation>
        <v-container>
          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Nome</label
          >
          <v-text-field
            hide-details="auto"
            required
            :rules="nomeRules"
            :readonly="!isEditing"
            outlined
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Nome"
            autocomplete="off"
            v-model="preset['nome']"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-text-field>

          <label
            class="label-color font-weight-600 d-block text-left text-h5 mt-2"
            >Descrizione</label
          >
          <v-textarea
            hide-details="auto"
            :readonly="!isEditing"
            outlined
            auto-grow
            rows="2"
            background-color="rgba(255,255,255,.9)"
            light
            placeholder="Descrizione"
            autocomplete="off"
            v-model="preset['descrizione']"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
              mb-4
            "
          ></v-textarea>

          <Passi :passi="preset['passi']" :isEditing="isEditing" @listChanged="listChanged" />
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-col cols="4" class="pt-0">
          <v-btn
            color="primary"
            width="100%"
            @click="submit"
            :disabled="isEditing && !formValid && loading"
            v-text="isEditing ? 'Salva' : 'Indietro'"
          ></v-btn>
        </v-col>
        <v-spacer />
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import apiPreset from "@/js/pages/preset";
import {
  nomeRules,
  requiredRules
} from "@/js/validationRules";
import Passi from "@/components/Passi.vue";

export default {
  name: "schedaPreset",
  props: {
    presetProp: Object,
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      token: this.$cookies.get("token_goldencar"),
      preset: this.presetProp || {},
      loading: false,
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
    };
  },
  components: {
    Passi
  },
  methods: {
    async submit() {
      if (this.isEditing) {
        if(!this.loading) {
          this.loading = true;
          if (this.$refs.form_data.validate() && this.formValid) {
            let data = this.preset;
            let update = await apiPreset.updateOne(this.token, data);
            if(update) this.$emit("chiudiDialogScheda", true);
            else alert("Errori durante la creazione, ricontrolla i dati");
          }
          this.loading = false;
        }
      } else this.$emit("chiudiDialogScheda");
    },
    listChanged(list) {
      this.preset['passi'] = list;
    }
  },
};
</script>
